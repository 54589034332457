import { config } from '@hp/config';
import PhoneNumber from 'awesome-phonenumber';
import countries from 'i18n-iso-countries';
import React, { useState } from 'react';

import { CountryInfoType } from '../atomic/phoneInput/types';
import { createPhoneInfo, format, getEmoji } from '../atomic/phoneInput/utils';

const currentLang = config.app.defaultLanguage;

countries.registerLocale(
  // eslint-disable-next-line
  require(`i18n-iso-countries/langs/${currentLang}.json`),
);

const nonStandardCountries = {
  ac: 'Ascension ‎island',
};

const getCountryObj: (code: string) => CountryInfoType = (code: string) => {
  const codeLower = code.toLowerCase();
  const name = countries.getName(codeLower, currentLang);
  return {
    code: codeLower,
    name,
    dialCode: PhoneNumber.getCountryCodeForRegionCode(code),
  };
};

const allCountries = PhoneNumber.getSupportedRegionCodes().map(getCountryObj);
allCountries
  .filter((country) => !country.name)
  .forEach((country) => {
    const foundAnotherCountryWithEqualDialCode = allCountries.find(
      (x) => x !== country && x.dialCode === country.dialCode && x.name,
    );
    if (foundAnotherCountryWithEqualDialCode) {
      country.name = foundAnotherCountryWithEqualDialCode.name;
    } else {
      country.name = nonStandardCountries[country.code];
      if (!country.name) {
        const e = new Error(
          `phoneinput: unknown country  ${country.code} --> using Code based name`,
        );
        console.error(e);
        country.name = `Region ${country.code}`;
      }
    }
  });

const getOptions = (countries: CountryInfoType[]) => {
  return countries
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ code, name, dialCode }) => (
      <option key={code} value={code.toUpperCase()}>
        {name} &nbsp;&nbsp; {getEmoji(code)}
        &nbsp;&nbsp;&nbsp;&nbsp; ( +{dialCode} )
      </option>
    ));
};

export const useCommonPhoneField = (value, defaultCountry = 'cz') => {
  const currentCountry = allCountries.find(
    (country) => country.code === defaultCountry,
  );
  const [phoneInfo, setPhoneInfo] = useState(
    createPhoneInfo(value, currentCountry, allCountries),
  );
  const [phoneValue, setPhoneValue] = useState<string>(format(phoneInfo));

  return {
    allCountries,
    getOptions,
    phoneInfo,
    setPhoneInfo,
    phoneValue,
    setPhoneValue,
  };
};
