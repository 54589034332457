import { config } from '@hp/config';
import { colors } from '@hp/theme';
import React, { FC } from 'react';
import ReactCountryFlag from 'react-country-flag';

import {
  Chevron,
  FlagWrapper,
  InputStyled,
  SelectStyled,
  Wrapper,
} from './styled';
import { PhoneInputProps } from './types';
import { usePhoneField } from './usePhoneField';

export const PhoneInput: FC<PhoneInputProps> = ({
  value,
  onChange,
  defaultCountry = config.app.defaultCountry.toLowerCase(),
  onFocus,
  onBlur,
  autoComplete,
  className,
  testId,
  ...otherProps
}) => {
  const {
    phoneInfo,
    handleChangeCountry,
    selectRef,
    inputRef,
    options,
    phoneValue,
    handleOnFocus,
    handleInputChange,
    handleOnBlur,
    handleKeyPress,
  } = usePhoneField({
    value,
    onChange,
    defaultCountry,
    onFocus,
    onBlur,
  });

  return (
    <Wrapper className={className}>
      <FlagWrapper>
        <ReactCountryFlag
          countryCode={phoneInfo.country.code}
          svg
          style={{
            width: '18px',
            height: '16px',
          }}
        />
      </FlagWrapper>
      <SelectStyled
        ref={selectRef}
        name="country"
        autoComplete={autoComplete === 'tel' ? 'country' : 'off'}
        onChange={handleChangeCountry}
        value={phoneInfo.country.code.toUpperCase()}
        data-test={`${testId}.country`}
      >
        {options}
      </SelectStyled>
      <Chevron color={colors.black} />

      <InputStyled
        {...otherProps}
        type="tel"
        ref={inputRef}
        autoComplete={
          autoComplete === 'tel'
            ? 'tel'
            : 'new-password' /* 'off' does not work, see https://stackoverflow.com/questions/12374442/chrome-ignores-autocomplete-off */
        }
        value={phoneValue}
        onFocus={handleOnFocus}
        onChange={handleInputChange}
        onBlur={handleOnBlur}
        onKeyDown={handleKeyPress}
        placeholder={`+${phoneInfo.country.dialCode}....`}
        data-test={`${testId}.input`}
      />
    </Wrapper>
  );
};
