import { ConditionalWrapper } from '@hp/atomic';
import { nameOf } from 'easy-tsnameof';
import { useField } from 'formik';
import React, { FC } from 'react';

import { RadioButton } from '../../atomic/RadioButton';
import {
  SwitchButtonFieldWrapperStyled_Button,
  SwitchButtonFieldWrapperStyled_Chip,
  SwitchButtonLabelWrapper_Button,
  SwitchButtonLabelWrapper_Chip,
} from './styled';
import {
  InputValueType,
  LabelWrapperProps,
  SwitchButtonProps,
  SwitchButtonType,
} from './types';

/** outer wrapper */
const SwitchButtonFieldWrapperStyled: FC<{ type: SwitchButtonType }> = ({
  type,
  children,
  ...props
}) => (
  <ConditionalWrapper
    condition={type === 'button'}
    wrapper={(children) => (
      <SwitchButtonFieldWrapperStyled_Button {...props}>
        {children}
      </SwitchButtonFieldWrapperStyled_Button>
    )}
    elseWrapper={(children) => (
      <SwitchButtonFieldWrapperStyled_Chip {...props}>
        {children}
      </SwitchButtonFieldWrapperStyled_Chip>
    )}
  >
    {children}
  </ConditionalWrapper>
);

/** inner wrapper */
const SwitchButtonLabelWrapper: FC<LabelWrapperProps> = ({
  type,
  children,
  ...props
}) => (
  <ConditionalWrapper
    condition={type === 'button'}
    wrapper={(children) => (
      <SwitchButtonLabelWrapper_Button {...props}>
        {children}
      </SwitchButtonLabelWrapper_Button>
    )}
    elseWrapper={(children) => (
      <SwitchButtonLabelWrapper_Chip {...props}>
        {children}
      </SwitchButtonLabelWrapper_Chip>
    )}
  >
    {children}
  </ConditionalWrapper>
);

export const SwitchButtonField: <T extends InputValueType = string>(
  props: SwitchButtonProps<T>,
) => JSX.Element = ({
  formId,
  name,
  extraMarginBetween,
  extraMarginBottom,
  hasIntValue,
  type = 'button',
  children,
}) => {
  const fieldName = nameOf(name);
  const [field, , { setValue }] = useField(fieldName);

  return (
    <SwitchButtonFieldWrapperStyled type={type}>
      {children.map(({ content, value, testId, disabled = false }, index) => {
        const checked = hasIntValue
          ? field.value == value
          : field.value === value;

        return (
          <SwitchButtonLabelWrapper
            className={`gaButton gaButtonSwitchLabel_${name}_${index}`}
            key={index}
            checked={checked}
            extraMarginBetween={extraMarginBetween}
            extraMarginBottom={extraMarginBottom}
            data-test={testId}
            disabled={disabled}
            type={type}
          >
            <RadioButton
              className={`gaButton gaButtonSwitchInput_${name}_${index}`}
              id={`${formId}_${fieldName}_${index}`}
              value={value}
              checked={checked}
              name={fieldName}
              onChange={() => {
                setValue(value);
              }}
              readOnly
              disabled={disabled}
            />
            {typeof content === 'function'
              ? content({ checked, index, value, disabled })
              : content}
          </SwitchButtonLabelWrapper>
        );
      })}
    </SwitchButtonFieldWrapperStyled>
  );
};
