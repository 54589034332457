import { AddressType, Country } from '@hp/core/shared';
import { inlineKeys, useI18n } from '@hp/locale';

import { isPhoneValid } from '../validations/isPhoneValid';
import { useYup } from './useYup';

/** tested for:
44
78C
89/98
66/89f
k lesu 99
K lesu 89/89
Olbramova 375
Hudcova 533/78C
žitava 65
https://regex101.com/r/QdWkZt/1 /^(.*\ )?\d+(\/\d+)?[a-zA-Z]?$/

tested also for: 
lesní 3B/2
https://regex101.com/r/YQ2LlS/1
 */
const streetWithNumberRegex = /^(.*\ )?\d+(\/\d+)?[a-zA-Z]?(\/\d+)?$/;

export const useYupSharedValidations = (yup: ReturnType<typeof useYup>) => {
  const i18n = useI18n();
  const streetValidation = yup
    .string()
    .max(35)
    .matches(
      streetWithNumberRegex,
      i18n._(inlineKeys.streetWithNumberValidation.key),
    );

  return {
    email: yup.string().email().max(50),
    name: yup.string().max(35).required(),
    nameOptional: yup.string().max(35),
    ico: yup.string().max(8),
    street: streetValidation.required(),
    streetOptional: yup.string(),
    city: yup.string().max(35).required(),
    cityOptional: yup.string().max(35),
    phone: (name: string, optional = false) =>
      yup
        .string()
        .test(name, i18n._(inlineKeys.phoneValidation.key), (phone) =>
          optional ? !phone || isPhoneValid(phone) : isPhoneValid(phone),
        ),
    note: yup.string().max(70),
    dic: yup.string().max(12),
    termsAgreement: yup.boolean().oneOf([true], i18n._(inlineKeys.terms.key)),
    subject: yup
      .mixed<AddressType>()
      .oneOf(Object.values(AddressType))
      .required(),
    country: yup.mixed<Country>().oneOf(Object.values(Country)).required(),
  };
};
