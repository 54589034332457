import { ArrowIcon, Button, ButtonType } from '@hp/atomic';
import { colors, elements } from '@hp/theme';
import { nameOf } from 'easy-tsnameof';
import { useField } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';

import { NumberButtonWrapper, NumberFieldWrapper } from './styled';
import { TextField, TextFieldProps } from './TextField';

const ArrowIconStyled = styled(ArrowIcon)`
  height: 20px;
`;

const ArrowIconSubtract = styled(ArrowIconStyled)`
  transform: rotate(180deg);
`;

export const NumberField: <Fields>(
  props: TextFieldProps<Fields>,
) => JSX.Element = (props) => {
  const fieldName = nameOf(props.name);
  const min = Number(props.min || 0);
  const max = Number(props.max || Number.MAX_SAFE_INTEGER);
  const [, { value }, helpers] = useField(fieldName);
  const [defaultValue] = useState(value || min);

  //todo: udelat komponentu abstraktne, aby nebylo zavisle na váze a divném kroku 6
  const incrementValue = (increment: number) => {
    const numberValue = Number.parseFloat(value);

    if (isNaN(numberValue) || numberValue > max || numberValue < min) {
      helpers.setValue(defaultValue);

      return;
    }

    // check difference and keep value in its range
    let newValue = numberValue + increment;

    if (increment > 0 && numberValue + increment > max) {
      newValue = max;
    } else if (increment < 0 && numberValue + increment < min) {
      newValue = min;
    } else {
      numberValue + increment;
    }

    if (newValue > max || newValue < min) return;

    helpers.setValue(newValue);
  };

  const cannotSubtract = value <= min || props.disabled;
  const cannotAdd = value >= max || props.disabled;
  const wierdStep = 6;
  const defaultStep = 5;
  const downStep = value === max ? -wierdStep : -defaultStep;
  const upStep = value === max - wierdStep ? wierdStep : defaultStep;

  return (
    <NumberFieldWrapper>
      <NumberButtonWrapper>
        <Button
          disabled={cannotSubtract}
          onClick={() => incrementValue(downStep)}
          buttonType={ButtonType.SECONDARY}
          type="button"
          height={elements.inputHeightSmall}
          data-test={`${props.testId}.button.decrement`}
        >
          <ArrowIconSubtract
            color={cannotSubtract ? colors.gray_mid : colors.red_dark}
          />
        </Button>
      </NumberButtonWrapper>

      <TextField {...props} testId={`${props.testId}.input`} />

      <NumberButtonWrapper>
        <Button
          disabled={cannotAdd}
          onClick={() => incrementValue(upStep)}
          buttonType={ButtonType.SECONDARY}
          type="button"
          height={elements.inputHeightSmall}
          data-test={`${props.testId}.button.increment`}
        >
          <ArrowIconStyled
            color={cannotAdd ? colors.gray_mid : colors.red_dark}
          />
        </Button>
      </NumberButtonWrapper>
    </NumberFieldWrapper>
  );
};
