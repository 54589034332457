import { config } from '@hp/config';
import { useLanguage } from '@hp/core/src/providers/LanguageProvider';
import { useObjectLocalStorage } from '@hp/utils';
import { nameOf } from 'easy-tsnameof';
import { FormikContextType, useField, useFormikContext } from 'formik';
import React, { useEffect, useRef } from 'react';

import { Error } from '../../atomic/Error';
import { FieldWrapper } from '../../atomic/FieldWrapper';
import { Input } from '../../atomic/Input';
import { Label } from '../../atomic/Label';
import { InputWrapper } from '../styled';
import { buildCityAddress, buildStreetAddress } from './helper';
import { AutocompleteType, TextFieldGooglePlacesProps } from './types';

// TODO: try to remove ts ignore

declare global {
  interface Window {
    google: any;
  }
}

let googleAutoComplete: {
  setFields: (arg0: string[]) => void;
  addListener: (arg0: string, arg1: () => Promise<void>) => void;
  getPlace: () => any;
};

const addressStructure = {
  route: 'long_name', // street
  premise: 'short_name',
  street_number: 'short_name',
  locality: 'long_name', // city
  sublocality: 'long_name', // city (if locality is not returned)
  sublocality_level_1: 'long_name', // city (if locality is not returned)
  postal_code: 'short_name',
};

const loadScript = (url: string, callback: () => void) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';

  //@ts-ignore
  if (script.readyState) {
    //@ts-ignore
    script.onreadystatechange = function () {
      //@ts-ignore
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        //@ts-ignore
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName('head')[0].appendChild(script);
};

const handleScriptLoad = (
  googleAutoCompleteRef: React.MutableRefObject<any>,
  countryRestriction: string,
  formik: FormikContextType<unknown>,
) => {
  // create autocomplete object, restricting search to geographical location types in cz
  //@ts-ignore
  googleAutoComplete = new window.google.maps.places.Autocomplete(
    googleAutoCompleteRef.current,
    {
      types: ['geocode'],
      componentRestrictions: { country: countryRestriction },
    },
  );
  // avoid paying for unused data by restricting the set of place fields that are returned
  googleAutoComplete.setFields(['address_components', 'formatted_address']);

  // handle place selection
  googleAutoComplete.addListener('place_changed', () =>
    handlePlaceSelect(formik),
  );
};

/** when user selects address from the dropdown, fill the form fields*/
const handlePlaceSelect = async (formik: FormikContextType<unknown>) => {
  const { setFieldError, setFieldTouched, setFieldValue } = formik;
  // get place details from autocomplete object
  const addressObject = googleAutoComplete.getPlace();

  const address = {
    route: '',
    premise: '',
    streetNumber: '',
  };
  const city = {
    locality: '',
    sublocality: '',
  };
  let postalCode = '';

  if (addressObject?.address_components) {
    // get each component of the address
    for (const addressComponent of addressObject.address_components) {
      const addressType = addressComponent.types[0];

      if (addressStructure[addressType]) {
        const value = addressComponent[addressStructure[addressType]];

        if (addressType === 'route') {
          address.route += value;
        }

        if (addressType === 'premise') {
          address.premise += value;
        }

        if (addressType === 'street_number') {
          address.streetNumber += value;
        }

        if (addressType === 'locality') {
          city.locality += value;
        }

        if (
          addressType === 'sublocality' ||
          addressType === 'sublocality_level_1'
        ) {
          city.sublocality += value;
        }

        if (addressType === 'postal_code') {
          postalCode += value.replace(/\s/g, '').replace(/\-/g, '');
        }
      }
    }
  }

  // set field values and touched
  setFieldError('street', null);
  setFieldValue('street', buildStreetAddress(address), false);
  setFieldTouched('street', true, false);

  setFieldError('city', null);
  setFieldValue('city', buildCityAddress(city), false);
  setFieldTouched('city', true, false);

  setFieldError('postalCode', null);
  setFieldValue('postalCode', postalCode, false);
  setFieldTouched('postalCode', true, false);

  formik.validateForm();
};

export const TextFieldGooglePlaces: <Fields>(
  props: TextFieldGooglePlacesProps<Fields>,
) => JSX.Element = ({
  autocompleteType,
  formId,
  name,
  label,
  onBlur,
  onChange,
  customError,
  autoComplete,
  testId,
  format = (v) => v,
  convert = (v) => v,
  validateImmediately,
  autoTrim = true,
  ...props
}) => {
  const fieldName = nameOf(name);

  const [
    { value, ...field },
    { error, touched },
    { setValue, setTouched },
  ] = useField(fieldName);

  const formik = useFormikContext();

  const googleAutoCompleteRef = useRef(null);

  const {
    app: { googleApiKey, defaultGoogleApiCountry },
  } = config;

  const [formData] = useObjectLocalStorage('howToSend');

  let country = defaultGoogleApiCountry;
  if (autocompleteType === AutocompleteType.SENDER) {
    country = formData.sendFromCountry as string;
  } else if (autocompleteType === AutocompleteType.RECIPIENT) {
    country = formData.sendToCountry as string;
  }

  const countryResctriction = country.toLowerCase();

  const { language } = useLanguage();

  const scriptLoaded = () =>
    handleScriptLoad(googleAutoCompleteRef, countryResctriction, formik);

  useEffect(() => {
    if (window?.google?.maps?.places) {
      //todo: when language is changed, we are not able to unload google.api from browser, because unload is not supported.
      //See: https://stackoverflow.com/questions/11444826/unload-google-maps-api
      //for this reason we do nothing, and user who switch language still see autocomplete in old language.
      scriptLoaded();
      return;
    }
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places&language=${language}`,
      scriptLoaded,
    );
  }, []);

  const inputValue = format(value) ?? '';

  return (
    <FieldWrapper>
      <InputWrapper error={!!error} touched={touched} withLabel={!!label}>
        {label && <Label htmlFor={`${formId}_${name}`}>{label}</Label>}
        <Input
          withLabel={!!label}
          id={`${formId}_${fieldName}`}
          className="googlePlacesInput"
          error={(error && touched) || !!customError}
          touched={touched}
          {...field}
          {...props}
          value={inputValue}
          onBlur={(e) => {
            if (autoTrim) {
              const trimed = inputValue.trim();
              if (trimed !== inputValue) {
                setValue(convert(trimed));
              }
            }

            field.onBlur(e);
            onBlur?.(e);
          }}
          onChange={(e) => {
            const value =
              props.type === 'number' && e.target.value !== ''
                ? Number(e.target.value)
                : e.target.value;

            setValue(convert(value));
            onChange?.(e);
            if (
              (typeof value === 'number' && validateImmediately !== false) ||
              validateImmediately === true
            ) {
              if (!touched) {
                setTouched(true, true);
              }
            }
          }}
          data-test={testId}
          autoComplete={
            autoComplete &&
            (autoComplete === 'off' ? 'new-password' : autoComplete)
          }
          ref={googleAutoCompleteRef}
          placeholder=""
        />
      </InputWrapper>

      <Error>{touched && error ? error : customError}</Error>
    </FieldWrapper>
  );
};
