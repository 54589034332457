import { colors } from '@hp/theme';
import React, { FC } from 'react';
import ReactSelect from 'react-select';
import styled from 'styled-components';

type RSelectProps = {
  options: { value: string; label: string }[];
};

const StyledReactSelect = styled(ReactSelect)`
  color: ${colors.black};
  max-width: 250px;
`;

export const RSelect: FC<RSelectProps> = ({ options, ...props }) => (
  <StyledReactSelect options={options} {...props} />
);
