import { colors, typography } from '@hp/theme';
import React, { FC, LabelHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

type LabelProps = {
  htmlFor?: string;
  checked?: boolean;
  disabled?: boolean;
  badge?: boolean;
  disabledColor?: string;
} & LabelHTMLAttributes<any>;

const LabelStyled = styled.label<
  Pick<LabelProps, 'checked' | 'disabled' | 'disabledColor' | 'badge'>
>`
  position: relative;

  color: ${({ disabled, disabledColor }) =>
    disabled ? disabledColor || colors.disabled : colors.black};
  font-weight: ${typography.fontWeight.light};
  font-size: ${typography.fontSize.bodySmall};

  cursor: pointer;
  padding-top: 3px;
  user-select: none;
  width: fit-content;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `};
  ${({ badge }) =>
    badge
      ? css`
          line-height: ${typography.lineHeight.body};
        `
      : css`
          display: block;
          line-height: ${typography.lineHeight.bodySmall};
          padding-left: 30px;
          :hover input ~ div {
            border: 2px solid ${colors.red_main};
          }
        `}
`;

export const CheckboxLabel: FC<LabelProps> = ({
  htmlFor,
  children,
  ...props
}) => (
  <LabelStyled htmlFor={htmlFor} {...props}>
    {children}
  </LabelStyled>
);
