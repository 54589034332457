import { config } from '@hp/config';
import { useLanguage } from '@hp/core/src/providers/LanguageProvider';
import { useCallback, useEffect, useState } from 'react';

const {
  app: { googleApiKey, defaultGoogleApiCountry },
} = config;

declare global {
  interface Window {
    google: any;
  }
}

export type LatLng = {
  lat: () => number;
  lng: () => number;
};

/** https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceGeometry */
export type PlaceGeomerty = {
  location?: LatLng;
};

/** types by https://developers.google.com/maps/documentation/geocoding/overview#Types */
export enum WellKnownAddressComponentType {
  'country' = 'country',
  'locality' = 'locality',
  'postal_code' = 'postal_code',
}

export type AddressComponent = {
  long_name: string;
  short_name: string;
  types: (string | WellKnownAddressComponentType)[];
};

/** https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult */
export type PlaceResult = {
  geometry?: PlaceGeomerty;
  address_components?: Array<AddressComponent>;
  adr_address?: any;
  name?: string;
  formatted_address?: string;
};

type GoogleAutoCompleteObj = {
  setFields: (arg0: string[]) => void;
  addListener: (arg0: string, arg1: () => Promise<void> | void) => void;
  getPlace: () => PlaceResult;
};

const loadScript = (url: string, callback: () => void) => {
  const script = document.createElement('script');
  script.type = 'text/javascript';

  //@ts-ignore
  if (script.readyState) {
    //@ts-ignore
    script.onreadystatechange = function () {
      //@ts-ignore
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        //@ts-ignore
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName('head')[0].appendChild(script);
};

const defaultCountryResctriction = defaultGoogleApiCountry.toLowerCase();
export const defaultFieldsArray = ['address_components', 'formatted_address'];
export const gpsFieldsArray = [...defaultFieldsArray, 'geometry.location'];

const initInput = (
  inputRef: React.MutableRefObject<any>,
  countryRestriction: string,
  handler: (place: PlaceResult) => void,
  fields: (keyof PlaceResult | string)[],
) => {
  const input = inputRef.current;
  // create autocomplete object, restricting search to geographical location types in cz
  //@ts-ignore
  const googleAutoComplete: GoogleAutoCompleteObj = new window.google.maps.places.Autocomplete(
    input,
    {
      types: ['geocode'],
      componentRestrictions: { country: countryRestriction },
    },
  );
  // avoid paying for unused data by restricting the set of place fields that are returned
  googleAutoComplete.setFields(fields);

  // handle place selection
  googleAutoComplete.addListener('place_changed', () => {
    return handler(googleAutoComplete.getPlace());
  });
  return {
    googleAutoComplete,
    dispose: () => {
      window.google.maps.event.clearInstanceListeners(input);
    },
  };
};

export const useGooglePlacesApi = (
  countryResctriction = defaultCountryResctriction,
) => {
  const { loading } = useGoogleApi();
  const dict = new Map<
    React.MutableRefObject<any>,
    ReturnType<typeof initInput> | (() => any)
  >();
  const [refs] = useState(dict);

  useEffect(() => {
    if (loading) return;
    // console.log('Google api loaded');
    [...refs.keys()].forEach((ref) => {
      if (ref.current) {
        const value = refs.get(ref);
        if (typeof value === 'function') refs.set(ref, value());
      }
    });
  }, [loading, countryResctriction, refs]);

  const registerInput = (
    input: React.MutableRefObject<any>,
    handler: (place: PlaceResult) => void,
    defaultFields: (keyof PlaceResult | string)[] = defaultFieldsArray,
  ) => {
    const entry = refs.get(input);
    if (entry) {
      return;
    }
    refs.set(
      input,
      loading
        ? () => initInput(input, countryResctriction, handler, defaultFields)
        : initInput(input, countryResctriction, handler, defaultFields),
    );
  };
  const unregisterInput = (input: React.MutableRefObject<any>) => {
    if (!refs.has(input)) return;
    if (!loading) {
      const googleObj = refs.get(input);
      if (typeof googleObj !== 'function') {
        //when still function, it means obj was not initilized yet
        googleObj.dispose();
      }
    }
    refs.delete(input);
  };

  const regCb = useCallback(registerInput, [
    countryResctriction,
    loading,
    refs,
  ]);
  const unregCb = useCallback(unregisterInput, [loading, refs]);

  //cleanup
  useEffect(() => {
    return () => {
      //console.log('cleanup', refs.size);
      [...refs.keys()].forEach((ref) => unregCb(ref));
    };
  }, [refs, unregCb]);

  return { registerInput: regCb, unregisterInput: unregCb };
};

export const useGoogleApi = () => {
  const { language } = useLanguage();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (window?.google?.maps?.places) {
      //todo: when language is changed, we are not able to unload google.api from browser, because unload is not supported.
      //See: https://stackoverflow.com/questions/11444826/unload-google-maps-api
      //for this reason we do nothing, and user who switch language still see autocomplete in old language.
      setLoading(false);
      return;
    }
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places&language=${language}`,
      () => setLoading(false),
    );
  }, []);
  return { loading };
};
