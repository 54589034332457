import React, {
  ChangeEventHandler,
  createRef,
  FocusEventHandler,
  FormEventHandler,
  useEffect,
  useState,
} from 'react';

import { useCommonPhoneField } from '../../hooks/useCommonPhoneField';
import { PhoneInfo } from './types';
import { createPhoneInfo, format, getDataValue, safeFocus } from './utils';

export const usePhoneField = ({
  value,
  onChange,
  defaultCountry,
  onFocus,
  onBlur,
}) => {
  const {
    allCountries,
    getOptions,
    phoneInfo,
    setPhoneInfo,
    phoneValue,
    setPhoneValue,
  } = useCommonPhoneField(value, defaultCountry);

  const [options, setOptions] = useState<JSX.Element[]>([]);
  const selectRef = createRef<HTMLSelectElement>();
  const inputRef = createRef<HTMLInputElement>();

  const reinitialize = (newPhoneInfo: PhoneInfo) => {
    setPhoneInfo(newPhoneInfo);
    setPhoneValueFormatted(newPhoneInfo);
    onChange?.(getDataValue(newPhoneInfo));
  };

  useEffect(() => {
    setOptions(getOptions(allCountries));
  }, []);

  useEffect(() => {
    const dataValue = getDataValue(phoneInfo);

    if (dataValue !== value) {
      reinitialize(createPhoneInfo(value, phoneInfo.country, allCountries));
    }
  }, [value]);

  const setPhoneValueFormatted = (phoneInfoParam?: PhoneInfo) => {
    const formatted = format(phoneInfoParam || phoneInfo);
    if (formatted !== phoneValue) {
      setPhoneValue(formatted);
    }
  };

  const handleOnFocus: FocusEventHandler<HTMLInputElement> = (event) => {
    onFocus?.(event);
  };

  const handleOnBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    if (event.relatedTarget === selectRef.current) {
      return;
    }
    setPhoneValueFormatted();
    onBlur?.(event);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = event;
    if (key === 'Home' || key === 'End') {
      setPhoneValueFormatted();
    }
  };

  const handleInputChange: FormEventHandler<HTMLInputElement> = (event) => {
    const { value, selectionStart } = event.target as HTMLInputElement;
    const replValue = value.replace(/[^\+\d\-\(\)\ \/]+/g, '');

    if (phoneValue === replValue) {
      return;
    }

    const newPhoneInfo = createPhoneInfo(
      replValue,
      phoneInfo.country,
      allCountries,
    );
    const isCursorOnEnd = selectionStart === value.length;
    const isAfterDialCode =
      selectionStart - 1 > newPhoneInfo.country.dialCode.toString().length;
    if (isCursorOnEnd && isAfterDialCode) {
      setPhoneValueFormatted(newPhoneInfo);
    } else {
      setPhoneValue(replValue);
    }
    setPhoneInfo(newPhoneInfo);
    onChange?.(getDataValue(newPhoneInfo));
  };

  const handleChangeCountry: ChangeEventHandler<HTMLSelectElement> = (
    event,
  ) => {
    const newCountryCode = event.target.value.toLowerCase();
    const newCountry = allCountries.find(
      (country) => country.code === newCountryCode,
    );
    reinitialize(
      createPhoneInfo(
        phoneValue.trim().startsWith('+') ? phoneInfo.national : phoneValue,
        newCountry,
        allCountries,
      ),
    );
    safeFocus(inputRef.current);
  };

  return {
    phoneInfo,
    handleChangeCountry,
    selectRef,
    inputRef,
    options,
    phoneValue,
    handleOnFocus,
    handleInputChange,
    handleOnBlur,
    handleKeyPress,
  };
};
