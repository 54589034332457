import { MessageDescriptor } from '@lingui/core';
import { nameOf } from 'easy-tsnameof';
import { useField } from 'formik';
import React from 'react';

import { Error } from '../atomic/Error';
import { FieldWrapper } from '../atomic/FieldWrapper';
import { Input, InputProps } from '../atomic/Input';
import { Label } from '../atomic/Label';
import { WithNameOfName } from '../types';
import { InputWrapper } from './styled';

export type TextFieldProps<Fields> = {
  formId: string;
  label?: React.ReactNode;
  placeholder?: MessageDescriptor;
  customError?: React.ReactNode;
  customIcon?: React.ReactNode;
  noCheckMark?: boolean;
  format?: (value: any) => InputProps['value'];
  convert?: (formValue: InputProps['value']) => any;
  testId?: string;
  /** starts validate on first change, even when input was not touched */
  validateImmediately?: boolean;
  validateOnlyOnBlur?: boolean | ((value: any) => boolean);
  autocorrect?: 'off';
  autocapitalize?: 'none';
  autoTrim?: boolean;
} & WithNameOfName<Fields, InputProps>;

export const TextField: <Fields>(
  props: TextFieldProps<Fields>,
) => JSX.Element = ({
  formId,
  name,
  label,
  onBlur,
  onChange,
  customError,
  autoComplete,
  testId,
  format = (v) => v,
  convert = (v) => v,
  validateImmediately,
  validateOnlyOnBlur,
  autoTrim = true,
  height,
  ...props
}) => {
  const fieldName = nameOf(name);
  const [
    { value, ...field },
    { error, touched },
    { setValue, setTouched, setError },
  ] = useField(fieldName);
  const inputValue = format(value) ?? '';
  return (
    <FieldWrapper>
      <InputWrapper
        error={!!error}
        touched={touched}
        withLabel={!!label}
        height={height}
      >
        {label && <Label htmlFor={`${formId}_${name}`}>{label}</Label>}
        <Input
          withLabel={!!label}
          id={`${formId}_${fieldName}`}
          error={(error && touched) || !!customError}
          touched={touched}
          {...field}
          {...props}
          value={inputValue}
          onBlur={(e) => {
            if (autoTrim && typeof inputValue === 'string') {
              const trimed = inputValue.trim();
              if (trimed !== inputValue) {
                setValue(convert(trimed));
              }
            }

            field.onBlur(e);
            onBlur?.(e);
          }}
          onChange={(e) => {
            const value =
              props.type === 'number'
                ? e.target.value !== ''
                  ? Number(e.target.value)
                  : null
                : format(e.target.value) ?? '';

            const resultValue = convert(value);
            const shouldValidate = !(typeof validateOnlyOnBlur === 'function'
              ? validateOnlyOnBlur(resultValue)
              : validateOnlyOnBlur);

            if (!shouldValidate) setError(null);

            setValue(resultValue, shouldValidate);
            onChange?.(e);
            if (
              //for number type validation is immediatelly, for text inputs only when required
              (typeof value === 'number' && validateImmediately !== false) ||
              validateImmediately === true
            ) {
              if (!touched) {
                setTouched(true, true);
              }
            }
          }}
          data-test={testId}
          autoComplete={
            autoComplete &&
            (autoComplete === 'off' ? 'new-password' : autoComplete)
          }
        />
      </InputWrapper>

      <Error>{touched && error ? error : customError}</Error>
    </FieldWrapper>
  );
};
