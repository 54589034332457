import { colors, typography } from '@hp/theme';
import React, { FC } from 'react';
import styled from 'styled-components';

type FormParaghraphType = {
  textAlign?: 'left' | 'center' | 'right';
};

const Paragraph = styled.p<FormParaghraphType>`
  color: ${colors.black};
  font-weight: ${typography.fontWeight.normal};
  font-size: ${typography.fontSize.body};
  line-height: ${typography.lineHeight.body};
  text-align: ${({ textAlign }) => textAlign};
  margin-bottom: 40px;
`;

export const FormParagraph: FC<FormParaghraphType> = ({
  children,
  textAlign = 'left',
  ...props
}) => (
  <Paragraph textAlign={textAlign} {...props}>
    {children}
  </Paragraph>
);
