import { Trans } from '@lingui/macro';
import React from 'react';
import * as Yup from 'yup';

const locale = (): Yup.LocaleObject => ({
  boolean: {
    oneOf: () => (
      <Trans id="yup.validation.boolean.oneOf">Tento údaj je povinný</Trans>
    ),
  },
  mixed: {
    default: () => (
      <Trans id="yup.validation.mixed.default">Tyto údaje jsou neplatné</Trans>
    ),
    required: () => (
      <Trans id="yup.validation.mixed.required">Tento údaj je povinný</Trans>
    ),
    oneOf: ({ values }) => (
      <Trans id="yup.validation.mixed.oneOf">
        Tento údaj musí mít jednu z následujících hodnot: {values}
      </Trans>
    ),
    notOneOf: ({ values }) => (
      <Trans id="yup.validation.mixed.notOneOf">
        Tento údaj nesmí být jednou z následujících hodnot: {values}
      </Trans>
    ),
    //@ts-ignore
    notType: (_ref) => {
      switch (_ref.type) {
        case 'number':
          return (
            <Trans id="yup.validation.mixed.notType.number">
              Vložte prosím platné číslo
            </Trans>
          );
        case 'string':
          return (
            <Trans id="yup.validation.mixed.notType.string">
              Vložte prosím platnou hodnotu
            </Trans>
          );
        default:
          return (
            <Trans id="yup.validation.mixed.notType.default">
              Vložte prosím platnou hodnotu
            </Trans>
          );
      }
    },
  },
  string: {
    length: ({ length }) => (
      <Trans id="yup.validation.string.length">
        Tento údaj musí obsahovat přesně {length.toString()} znaků
      </Trans>
    ),
    min: ({ min }) => (
      <Trans id="yup.validation.string.min">
        Tento údaj musí obsahovat minimálně {min.toString()} znaků
      </Trans>
    ),
    max: ({ max }) => (
      <Trans id="yup.validation.string.max">
        Tento údaj musí obsahovat maximálně {max.toString()} znaků
      </Trans>
    ),
    matches: ({ regex }) => (
      <Trans id="yup.validation.string.matches">
        Tento údaj musí odpovídat následujícímu: &quot;{regex}&quot;
      </Trans>
    ),
    email: () => (
      <Trans id="yup.validation.string.email">
        Vyplňte prosím e-mailovou adresu ve správném formátu
      </Trans>
    ),
    url: () => (
      <Trans id="yup.validation.string.url">
        Vyplňte prosím webovou adresu ve správném formátu
      </Trans>
    ),
    trim: () => (
      <Trans id="yup.validation.string.trim">
        Toto pole musí obsahovat ořezaný řetězec
      </Trans>
    ),
    lowercase: () => (
      <Trans id="yup.validation.string.lowercase">
        Toto pole musí být řetězec malých písmen
      </Trans>
    ),
    uppercase: () => (
      <Trans id="yup.validation.string.uppercase">
        Toto pole musí být řetězec velkých písmen
      </Trans>
    ),
  },
  number: {
    min: ({ min }) => (
      <Trans id="yup.validation.number.min">
        Tady musíte napsat hodnotu větší nebo rovno {min.toString()}
      </Trans>
    ),
    max: ({ max }) => (
      <Trans id="yup.validation.number.max">
        Tady musíte napsat hodnotu menší nebo rovno {max.toString()}
      </Trans>
    ),
    lessThan: ({ less }) => (
      <Trans id="yup.validation.number.lessThan">
        Tady musíte napsat hodnotu menší než {less.toString()}
      </Trans>
    ),
    moreThan: ({ more }) => (
      <Trans id="yup.validation.number.moreThan">
        Tady musíte napsat hodnotu menší než {more.toString()}
      </Trans>
    ),
    positive: () => (
      <Trans id="yup.validation.number.positive">
        Tady musí být kladné číslo
      </Trans>
    ),
    negative: () => (
      <Trans id="yup.validation.number.negative">
        Tady musí být záporné číslo
      </Trans>
    ),
    integer: () => (
      <Trans id="yup.validation.number.integer">Tady napište celé číslo</Trans>
    ),
  },
  date: {
    min: ({ min }) => (
      <Trans id="yup.validation.date.min">
        Toto pole musí být pozdější než {min.toString()}
      </Trans>
    ),
    max: ({ max }) => (
      <Trans id="yup.validation.date.max">
        Toto pole musí být dříve než {max.toString()}
      </Trans>
    ),
  },
  object: {
    noUnknown: () => (
      <Trans id="yup.validation.object.noUnknown">
        Toto pole nemůže mít klíče, které nejsou specifikovány ve tvaru objektu
      </Trans>
    ),
  },

  array: {
    min: ({ min }) => (
      <Trans id="yup.validation.array.min">
        Toto pole musí mít alespoň {min.toString()} položek
      </Trans>
    ),
    max: ({ max }) => (
      <Trans id="yup.validation.array.max">
        Toto pole musí mít hodnotu menší nebo rovnou {max.toString()} položek
      </Trans>
    ),
  },
});

export const useYup = () => {
  Yup.setLocale(locale());
  return Yup;
};
