import { inlineKeys } from '@hp/locale';
import { t } from '@lingui/macro';
import { isEqual } from 'date-fns';
import { nameOf } from 'easy-tsnameof';
import { useField } from 'formik';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Error } from '../atomic/Error';
import { FieldWrapper } from '../atomic/FieldWrapper';
import { Input, InputProps } from '../atomic/Input';
import { Label } from '../atomic/Label';
import { Select } from '../atomic/Select';
import { Exclamation } from '../atomic/styled';
import { DAYS, MONTHS, YEARS } from '../constants';
import { WithNameOfName } from '../types';

type DatePickerFieldProps<Fields> = {
  formId: string;
  label: React.ReactNode;
  preview?: boolean;
} & WithNameOfName<Fields, InputProps>;

const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

const InfoIcons = styled.div`
  position: absolute;
  right: 0;
  top: 10px;
  display: flex;
  flex-direction: row;
`;

const SelectDate = styled(Select)`
  min-width: 80px;
  margin-right: 20px;

  :first-child {
    min-width: 50px;
  }

  :nth-child(3) {
    min-width: 65px;
    margin-right: 0;
  }
`;

export const DatePickerField: <Fields>(
  props: DatePickerFieldProps<Fields>,
) => JSX.Element = ({ formId, name, label, preview, ...props }) => {
  const fieldName = nameOf(name);
  const [
    field,
    { error, touched },
    { setValue, setTouched, setError },
  ] = useField(fieldName);

  const init = field.value ? field.value : null;

  const [previousValue, setPreviousValue] = useState<Date>(init);
  const [day, setDay] = useState<number>(init ? init.getDate() : null);
  const [month, setMonth] = useState<number>(init ? init.getMonth() : null);
  const [year, setYear] = useState<number>(init ? init.getFullYear() : null);

  const handleChange = (
    event: React.FormEvent<HTMLSelectElement>,
    setFunction: Dispatch<SetStateAction<number>>,
  ) => {
    setFunction(Number(event.currentTarget.value));
  };

  const handleBlur = () => {
    if (
      !!day &&
      (!!month || month === 0) &&
      !!year &&
      isDateValid(day, month, year)
    ) {
      setTouched(true);
    }
  };

  const isDateValid = (day: number, month: number, year: number): boolean => {
    const date = new Date(year, month, day);

    return (
      date.getDate() === day &&
      date.getMonth() === month &&
      date.getFullYear() === year
    );
  };

  useEffect(() => {
    if (!!day && (!!month || month === 0) && !!year) {
      const date = new Date(field.value);

      if (!isDateValid(day, month, year)) {
        setError(inlineKeys.dateValidation.trans);
        return;
      }

      date.setFullYear(year);
      date.setMonth(month);
      date.setDate(day);
      setPreviousValue(date);
      setValue(date);
    } else {
      setPreviousValue(null);
    }
  }, [day, month, year]);

  useEffect(() => {
    if (!isEqual(field.value, previousValue)) {
      const date = new Date(field.value);
      setDay(date.getDate());
      setMonth(date.getMonth());
      setYear(date.getFullYear());
      setTouched(false);
    }
  }, [field.value]);

  return (
    <FieldWrapper>
      <Label htmlFor={`${formId}_${name}`}>{label}</Label>
      <SelectWrapper>
        <SelectDate
          firstOption={t('common.field.birthday.day')`Den`}
          options={DAYS}
          value={day}
          error={!!error}
          touched={touched}
          preview={preview}
          onBlur={handleBlur}
          onChange={(event) => handleChange(event, setDay)}
          noCheckmark
        />
        <SelectDate
          firstOption={t('common.field.birthday.month')`Měsíc`}
          options={MONTHS}
          value={month}
          error={!!error}
          touched={touched}
          preview={preview}
          onBlur={handleBlur}
          onChange={(event) => handleChange(event, setMonth)}
          noCheckmark
        />
        <SelectDate
          firstOption={t('common.field.birthday.year')`Rok`}
          options={YEARS}
          value={year}
          error={!!error}
          touched={touched}
          moveChevron={!!error}
          preview={preview}
          onBlur={handleBlur}
          onChange={(event) => handleChange(event, setYear)}
        />
        <Input id={`${formId}_${name}`} type="hidden" {...field} {...props} />
        <InfoIcons>
          {touched && error && <Exclamation>!</Exclamation>}
        </InfoIcons>
      </SelectWrapper>
      <Error>{touched && error}</Error>
    </FieldWrapper>
  );
};
