import { colors, typography } from '@hp/theme';
import React, { FC } from 'react';
import styled from 'styled-components';

const ErrorSegment = styled.div`
  padding-top: 4px;
  color: ${colors.error};
  font-size: ${typography.fontSize.bodyMicro};
  line-height: ${typography.lineHeight.bodyMicro};
`;

export const Error: FC = ({ children, ...props }) => {
  return children ? <ErrorSegment {...props}>{children}</ErrorSegment> : null;
};
