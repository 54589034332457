import { Country } from '@hp/core/shared';
import { useLanguage } from '@hp/core/src/providers/LanguageProvider';
import { labels } from '@hp/locale';
import { MessageDescriptor } from '@lingui/core';
import { flag } from 'country-emoji';
import countryName from 'country-name';
import React from 'react';

import { SelectField, SelectFieldProps } from './SelectField';

export type CountrySelectFieldProps<T> = Omit<
  SelectFieldProps<T, string>,
  'options' | 'valueView' | 'noCheckmark'
> & {
  filter?: (country: Country) => boolean;
  showAsCode?: boolean;
  secondary?: boolean;
};

export const CountrySelectField = function <TFields>({
  label = labels.country,
  filter = () => true,
  showAsCode,
  lastOption,
  ...props
}: CountrySelectFieldProps<TFields>) {
  const { language } = useLanguage();

  const getCountryLabel = (value: Country) => {
    return (
      <span>
        {flag(value)} &nbsp; {showAsCode ? value : countryName(value, language)}
      </span>
    );
  };

  const countryOptions = Object.values(Country)
    .filter(filter)
    .map((country) => ({
      value: country,
      label: `${flag(country)} ${countryName(country, language)}` as
        | MessageDescriptor
        | string,
    }));

  return (
    <SelectField<TFields>
      label={label}
      options={countryOptions}
      noCheckmark
      valueView={getCountryLabel}
      lastOption={lastOption}
      {...props}
    />
  );
};
