import { MessageDescriptor } from '@lingui/core';
import { nameOf } from 'easy-tsnameof';
import { useField } from 'formik';
import React, { ChangeEvent } from 'react';

import { Error } from '../atomic/Error';
import { FieldWrapper } from '../atomic/FieldWrapper';
import { Label } from '../atomic/Label';
import { Select, SelectProps } from '../atomic/Select';
import { WithNameOfName } from '../types';
import { FormattedValueWrapper, InputWrapper } from './styled';

export type SelectFieldProps<
  Fields,
  TValueType extends React.OptionHTMLAttributes<
    HTMLOptionElement
  >['value'] = string
> = {
  formId: string;
  label?: React.ReactNode;
  placeholder?: MessageDescriptor;
  onChange?: (e: ChangeEvent) => void;
  onBlur?: (e: FocusEvent) => void;
  customError?: React.ReactNode;
  preview?: boolean;
  valueView?: (value: TValueType) => React.ReactNode;
  secondary?: boolean;
} & WithNameOfName<Fields, SelectProps<TValueType>>;

export const SelectField: <
  Fields,
  TValueType extends React.OptionHTMLAttributes<
    HTMLOptionElement
  >['value'] = string
>(
  props: SelectFieldProps<Fields, TValueType>,
) => JSX.Element = ({
  formId,
  name,
  label,
  onBlur,
  onChange,
  customError,
  valueView,
  secondary,
  ...props
}) => {
  const fieldName = nameOf(name);
  const [field, { error, touched }] = useField(fieldName);

  return (
    <FieldWrapper>
      <InputWrapper
        error={!!error}
        touched={touched}
        withLabel
        forSelect
        secondary={secondary}
      >
        {label && <Label htmlFor={`${formId}_${fieldName}`}>{label}</Label>}

        <Select
          withLabel={!!label}
          id={`${formId}_${fieldName}`}
          error={(error && touched) || !!customError}
          touched={touched}
          {...field}
          {...props}
          onBlur={(e) => {
            field.onBlur(e);
            onBlur?.(e);
          }}
          onChange={(e) => {
            field.onChange(e);
            onChange?.(e);
          }}
          secondary={secondary}
        />

        {valueView && (
          <FormattedValueWrapper secondary={secondary}>
            {valueView(field.value)}
          </FormattedValueWrapper>
        )}
      </InputWrapper>
      <Error>{touched && error ? error : customError}</Error>
    </FieldWrapper>
  );
};
