import { useI18n } from '@hp/locale';
import { breakpoints, colors, typography } from '@hp/theme';
import { MessageDescriptor } from '@lingui/core';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { Checkmark, CheckmarkWrapper, Exclamation } from './styled';

export type TextAreaProps = {
  placeholderDescriptor?: MessageDescriptor;
  error?: boolean;
  touched?: boolean;
  preview?: boolean;
} & Omit<
  React.HTMLProps<HTMLTextAreaElement>,
  'as' | 'ref' | 'label'
> /* due to ts/react conflict */;

const Wrapper = styled.div`
  position: relative;
`;

const InfoIcons = styled.div`
  position: absolute;
  right: -28px;
  top: -28px;
  display: flex;
  flex-direction: row;
`;

const TextAreaStyled = styled.textarea<TextAreaProps>`
  color: ${colors.black};
  font-size: ${typography.fontSize.body};
  line-height: ${typography.lineHeight.body};
  width: 100%;
  height: 100px;
  @media (min-width: ${breakpoints.minDesktop}) {
    height: 50px;
  }
  background-color: transparent;
  padding: 0;
  border: none;
  outline: none;
  resize: none;

  ::placeholder {
    color: ${colors.gray_warm};
    opacity: 1; /* Firefox */
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${colors.white} inset !important;
  }
`;

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    { error, touched, preview, placeholderDescriptor, value, ...props },
    ref,
  ) => {
    const i18n = useI18n();

    return (
      <Wrapper>
        <TextAreaStyled
          placeholder={
            placeholderDescriptor ? i18n._(placeholderDescriptor.id) : null
          }
          ref={ref}
          error={error}
          touched={touched}
          preview={preview}
          value={value}
          {...props}
        />
        <InfoIcons>
          {touched && error && <Exclamation>!</Exclamation>}
          {touched && !error && value && (
            <CheckmarkWrapper>
              <Checkmark />
            </CheckmarkWrapper>
          )}
        </InfoIcons>
      </Wrapper>
    );
  },
);
