import { spacing } from '@hp/theme';
import styled from 'styled-components';

import { FieldWrapper } from '../../atomic/FieldWrapper';

export const RadioButtonFieldWrapperStyled = styled(FieldWrapper)`
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

type LabelWrapperProps = {
  disabled?: boolean;
};

export const RadioButtonLabelWrapper = styled.label<LabelWrapperProps>`
  cursor: ${({ disabled }) => (disabled ? 'auto !important' : 'pointer')};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  margin: ${spacing.s} ${spacing.xs};
`;
