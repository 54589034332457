import { nameOf } from 'easy-tsnameof';
import { useField } from 'formik';
import React from 'react';

import { Error } from '../atomic/Error';
import { FieldWrapper } from '../atomic/FieldWrapper';
import { Hint } from '../atomic/Hint';
import { Input, InputProps } from '../atomic/Input';
import { Label } from '../atomic/Label';
import { WithNameOfName } from '../types';
import { InputWrapper } from './styled';

export type PasswordFieldProps<Fields> = {
  formId: string;
  label: React.ReactNode;
  hint?: React.ReactNode;
  testId?: string;
} & WithNameOfName<Fields, InputProps>;

export const PasswordField: <Fields>(
  props: PasswordFieldProps<Fields>,
) => JSX.Element = ({ formId, name, label, hint, testId, ...props }) => {
  const fieldName = nameOf(name);
  const [field, { error, touched }] = useField(fieldName);

  return (
    <FieldWrapper>
      <InputWrapper error={!!error} touched={touched} withLabel={!!label}>
        {label && <Label htmlFor={`${formId}_${name}`}>{label}</Label>}
        <Input
          autoComplete="new-password"
          withLabel={!!label}
          id={`${formId}_${fieldName}`}
          error={error && touched}
          touched={touched}
          type="password"
          {...field}
          {...props}
          data-test={testId}
        />
      </InputWrapper>
      {!!hint && !(touched && error) ? (
        <Hint>{hint}</Hint>
      ) : (
        <Error>{touched && error}</Error>
      )}
    </FieldWrapper>
  );
};
