import { nameOf } from 'easy-tsnameof';
import { useField } from 'formik';
import React from 'react';

import { RadioButton } from '../../atomic/RadioButton';
import {
  RadioButtonFieldWrapperStyled,
  RadioButtonLabelWrapper,
} from './styled';
import { InputValueType, RadioButtonProps } from './types';

export const RadioButtonField: <T extends InputValueType = string>(
  props: RadioButtonProps<T>,
) => JSX.Element = ({ formId, name, hasIntValue, children }) => {
  const fieldName = nameOf(name);
  const [field, , { setValue }] = useField(fieldName);

  return (
    <RadioButtonFieldWrapperStyled>
      {children.map(({ content, value, testId, disabled = false }, index) => {
        const checked = hasIntValue
          ? field.value == value
          : field.value === value;

        return (
          <RadioButtonLabelWrapper
            className={`gaButton gaButtonRadioLabel_${name}_${index}`}
            key={index}
            data-test={testId}
            disabled={disabled}
          >
            <RadioButton
              className={`gaButton gaButtonRadioInput_${name}_${index}`}
              id={`${formId}_${fieldName}_${index}`}
              value={value}
              checked={checked}
              name={fieldName}
              onChange={() => {
                setValue(value);
              }}
              readOnly
              isVisible
              disabled={disabled}
            />

            {typeof content === 'function'
              ? content({ checked, index, value, disabled })
              : content}
          </RadioButtonLabelWrapper>
        );
      })}
    </RadioButtonFieldWrapperStyled>
  );
};
