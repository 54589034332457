import { t } from '@lingui/macro';

const years = [];
const currentYear = new Date().getFullYear();
let startYear = currentYear - 100;
while (startYear <= currentYear) {
  years.push({ value: startYear.toString(), label: startYear.toString() });
  startYear++;
}

export const YEARS = years;

export const MONTHS = [
  { value: '0', label: t('months.january')`Leden` },
  { value: '1', label: t('months.february')`Únor` },
  { value: '2', label: t('months.march')`Březen` },
  { value: '3', label: t('months.april')`Duben` },
  { value: '4', label: t('months.may')`Květen` },
  { value: '5', label: t('months.june')`Červen` },
  { value: '6', label: t('months.july')`Červenec` },
  { value: '7', label: t('months.august')`Srpen` },
  { value: '8', label: t('months.september')`Září` },
  { value: '9', label: t('months.october')`Říjen` },
  { value: '10', label: t('months.november')`Listopad` },
  { value: '11', label: t('months.december')`Prosinec` },
];

export const DAYS = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: '13', label: '13' },
  { value: '14', label: '14' },
  { value: '15', label: '15' },
  { value: '16', label: '16' },
  { value: '17', label: '17' },
  { value: '18', label: '18' },
  { value: '19', label: '19' },
  { value: '20', label: '20' },
  { value: '21', label: '21' },
  { value: '22', label: '22' },
  { value: '23', label: '23' },
  { value: '24', label: '24' },
  { value: '25', label: '25' },
  { value: '26', label: '26' },
  { value: '27', label: '27' },
  { value: '28', label: '28' },
  { value: '29', label: '29' },
  { value: '30', label: '30' },
  { value: '31', label: '31' },
];
