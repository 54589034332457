import { colors, typography } from '@hp/theme';
import React, { FC, LabelHTMLAttributes } from 'react';
import styled from 'styled-components';

type LabelProps = {
  htmlFor?: string;
  checked?: boolean;
} & LabelHTMLAttributes<any>;

const LabelStyled = styled.label<Pick<LabelProps, 'checked'>>`
  position: relative;
  display: block;
  cursor: pointer;
  color: ${({ checked }) => (checked ? colors.white : colors.gray_mid)};
  font-size: ${typography.fontSize.body};
  line-height: ${typography.lineHeight.body};
  font-weight: ${({ checked }) =>
    checked ? typography.fontWeight.normal : typography.fontWeight.light};
  padding-left: 30px;
  user-select: none;
`;

export const RadioButtonLabel: FC<LabelProps> = ({
  htmlFor,
  checked,
  children,
  ...props
}) => (
  <LabelStyled htmlFor={htmlFor} checked={checked} {...props}>
    {children}
  </LabelStyled>
);
