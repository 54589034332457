import { ConditionalWrapper } from '@hp/atomic';
import { nameOf } from 'easy-tsnameof';
import { useField } from 'formik';
import React from 'react';

import { Checkbox, CheckboxProps } from '../atomic/Checkbox';
import { CheckboxLabel } from '../atomic/CheckboxLabel';
import { Error } from '../atomic/Error';
import { FieldWrapper } from '../atomic/FieldWrapper';
import { WithNameOfName } from '../types';

type CheckboxFieldProps<Fields> = {
  formId: string;
  label: React.ReactNode;
  disabledColor?: string;
  badge?: boolean;
} & WithNameOfName<Fields, CheckboxProps>;

export const CheckboxField: <Fields>(
  props: CheckboxFieldProps<Fields>,
) => JSX.Element = ({
  formId,
  name,

  className,
  disabledColor,
  badge,
  ...props
}) => {
  const fieldName = nameOf(name);
  const [field, { touched, error }, { setTouched }] = useField(fieldName);

  return (
    <ConditionalWrapper
      condition={!badge}
      wrapper={(ch) => <FieldWrapper>{ch}</FieldWrapper>}
    >
      <>
        <CheckboxLabel
          checked={field.value}
          disabled={props.disabled}
          className={className}
          disabledColor={disabledColor}
          badge={badge}
        >
          <Checkbox
            className={className}
            error={!!touched && !!error}
            id={`${formId}_${name}`}
            checked={field.value}
            badge={badge}
            {...field}
            {...props}
            onClick={(e) => {
              if (!props.disabled) {
                field.onChange(e);
                setTouched(true);
              }
            }}
            disabledColor={disabledColor}
          />
        </CheckboxLabel>
        <Error>{touched && error}</Error>
      </>
    </ConditionalWrapper>
  );
};
