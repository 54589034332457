import { ChevronIcon } from '@hp/atomic';
import { typography } from '@hp/theme';
import styled from 'styled-components';

import { Input } from '../Input';

export const Chevron = styled(ChevronIcon)`
  transform: rotate(90deg);
  width: 6px;
  position: absolute;
  top: -8px;
  left: 40px;
  z-index: 2;
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const FlagWrapper = styled.div`
  position: absolute;
  bottom: 8px;
  left: 10px;
  z-index: 2;
`;

export const SelectStyled = styled.select`
  z-index: 3;
  font-size: 16px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 64px;
  height: 100%;
  -webkit-appearance: button;
  > option {
    font-size: ${typography.fontSize.bodyLarge};
  }
`;

export const InputStyled = styled(Input)`
  padding-left: 64px;
`;
