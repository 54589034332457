import { breakpoints } from '@hp/theme';
import React, { FC } from 'react';
import styled from 'styled-components';

type ColumnFieldWrapperProps = {
  fullWidth?: boolean;
};

const ColumnFieldWrapperStyled = styled.div<ColumnFieldWrapperProps>`
  flex: 100%;
  max-width: ${({ fullWidth }) => (fullWidth ? 'initial' : '400px')};
  padding-right: 0;

  @media (min-width: ${breakpoints.minDesktop}) {
    flex: 50%;
    max-width: initial;
    padding-right: ${({ fullWidth }) => (fullWidth ? 'initial' : '50px')};
  }
`;

export const ColumnFieldWrapper: FC<ColumnFieldWrapperProps> = ({
  fullWidth = false,
  children,
}) => (
  <ColumnFieldWrapperStyled fullWidth={fullWidth}>
    {children}
  </ColumnFieldWrapperStyled>
);
