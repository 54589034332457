import { Badge } from '@hp/components';
import { colors } from '@hp/theme';
import React, { FC } from 'react';
import styled from 'styled-components';

export type CheckboxProps = {
  error?: boolean;
  disabledColor?: string;
  badge?: boolean;
  label: React.ReactNode;
} & Omit<
  React.HTMLProps<HTMLInputElement>,
  'as' | 'ref' | 'label'
> /* due to ts/react conflict */;

type InputStyledProps = Pick<CheckboxProps, 'error' | 'disabled' | 'value'>;

const InputStyled = styled.input<InputStyledProps>`
  position: absolute;
  display: none;
  height: 0;
  width: 0;
  cursor: pointer;

  &:checked ~ div {
    background-color: ${({ disabled }) =>
      disabled ? colors.disabled : colors.red_main};
    border: 2px solid
      ${({ disabled, error }) =>
        error
          ? colors.red_main
          : disabled
          ? colors.gray_light
          : colors.red_main};

    &:after {
      display: block;
    }
  }
`;

const CustomCheckbox = styled.div<
  Pick<CheckboxProps, 'error' | 'disabled' | 'disabledColor'>
>`
  position: absolute;
  top: 2px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: ${colors.transparent};
  border: 2px solid
    ${({ disabled, error, disabledColor }) =>
      error
        ? colors.red_main
        : disabled
        ? disabledColor || colors.disabled
        : colors.gray_mid};
  border-radius: 4px;

  &:after {
    position: absolute;
    display: none;
    content: '';
    left: 4px;
    top: 1px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

export const Checkbox: FC<CheckboxProps> = (props) => {
  const { badge, label, ...rest } = props;
  return (
    <>
      <InputStyled type="checkbox" {...rest} />
      {badge ? (
        <Badge
          bgColor={props.checked ? colors.red_dark : colors.disabled}
          textColor={props.checked ? colors.white : colors.black}
        >
          {' '}
          {label}
        </Badge>
      ) : (
        <>
          <CustomCheckbox
            error={props.error}
            disabled={props.disabled}
            disabledColor={props.disabledColor}
          />
          <span>{label}</span>
        </>
      )}
    </>
  );
};
