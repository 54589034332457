import { colors } from '@hp/theme';
import React, { FC } from 'react';
import styled from 'styled-components';

const FieldWrapperStyled = styled.div`
  color: ${colors.black};
  width: 100%;
`;

export const FieldWrapper: FC = ({ children, ...props }) => (
  <FieldWrapperStyled {...props}>{children}</FieldWrapperStyled>
);
