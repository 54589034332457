import { colors, typography } from '@hp/theme';
import React, { FC } from 'react';
import styled from 'styled-components';

const InfoSegment = styled.div`
  padding-top: 4px;
  color: ${colors.gray_mid};
  font-size: ${typography.fontSize.bodyMicro};
  line-height: ${typography.lineHeight.bodyMicro};
  text-align: right;
`;

export const InfoMessage: FC = ({ children, ...props }) => {
  return <InfoSegment {...props}>{children}</InfoSegment>;
};
