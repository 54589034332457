import { ConditionalWrapper, FlexBox } from '@hp/atomic';
import { breakpoints, spacing } from '@hp/theme';
import { FormikContextType, FormikProvider } from 'formik';
import React, { FC, KeyboardEvent } from 'react';
import styled from 'styled-components';

type FormWrapperProps = {
  formik: FormikContextType<any>;
  flex?: boolean;
  marginTopSize?: Extract<keyof typeof spacing, 'zero' | 'm' | 'l' | 'xl'>;
  marginBottomSize?: Extract<keyof typeof spacing, 'zero' | 'm' | 'l' | 'xl'>;
  disableAutoComplete?: boolean;
  onKeyDown?: (event: KeyboardEvent) => void;
};

const Form = styled.form<
  Pick<FormWrapperProps, 'marginBottomSize' | 'marginTopSize'>
>`
  width: 100%;
  margin: 24px 0;
  margin-top: ${({ marginTopSize }) => marginTopSize && spacing[marginTopSize]};
  margin-bottom: ${({ marginBottomSize }) =>
    marginBottomSize && spacing[marginBottomSize]};

  @media (min-width: ${breakpoints.minDesktop}) {
    margin-bottom: 24px;
  }
`;

export const FormWrapper: FC<FormWrapperProps> = ({
  children,
  formik,
  flex,
  marginTopSize,
  marginBottomSize,
  disableAutoComplete,
  onKeyDown,
}) => {
  return (
    <FormikProvider value={formik}>
      <Form
        onSubmit={formik.handleSubmit}
        marginTopSize={marginTopSize}
        marginBottomSize={marginBottomSize}
        autoComplete={disableAutoComplete && 'new-password'}
        onKeyDown={onKeyDown}
      >
        <ConditionalWrapper
          condition={flex}
          wrapper={(children) => <FlexBox>{children}</FlexBox>}
        >
          {children}
        </ConditionalWrapper>
      </Form>
    </FormikProvider>
  );
};
