import { MessageDescriptor } from '@lingui/core';

import { InputProps } from '../../atomic/Input';
import { WithNameOfName } from '../../types';

export enum AutocompleteType {
  SENDER = 'sender',
  RECIPIENT = 'recipient',
}

export type TextFieldGooglePlacesProps<Fields> = {
  autocompleteType: AutocompleteType;
  formId: string;
  label?: React.ReactNode;
  placeholder?: MessageDescriptor;
  customError?: React.ReactNode;
  customIcon?: React.ReactNode;
  noCheckMark?: boolean;
  format?: (value: any) => InputProps['value'];
  convert?: (formValue: InputProps['value']) => any;
  testId?: string;
  validateImmediately?: boolean;
  autoTrim?: boolean;
} & WithNameOfName<Fields, InputProps>;
