import { LinkButton } from '@hp/atomic';
import { breakpoints } from '@hp/theme';
import styled from 'styled-components';

export const FormBackButton = styled(LinkButton)`
  display: none;

  @media (min-width: ${breakpoints.minDesktop}) {
    display: block;
  }
`;
