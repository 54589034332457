import { MessageDescriptor } from '@lingui/core';
import { Trans } from '@lingui/react';
import { nameOf } from 'easy-tsnameof';
import { useField } from 'formik';
import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Error } from '../atomic/Error';
import { FieldWrapper } from '../atomic/FieldWrapper';
import { InfoMessage } from '../atomic/InfoMessage';
import { Label } from '../atomic/Label';
import { TextArea, TextAreaProps } from '../atomic/TextArea';
import { WithNameOfName } from '../types';
import { TextAreaWrapper } from './styled';

type TextAreaFieldProps<Fields> = {
  formId: string;
  label?: React.ReactNode;
  placeholder?: MessageDescriptor;
  preview?: boolean;
  onChange?: (e: ChangeEvent) => void;
  onBlur?: (e: FocusEvent) => void;
  testId?: string;
} & WithNameOfName<Fields, TextAreaProps>;

const MessagesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TextAreaField: <Fields>(
  props: TextAreaFieldProps<Fields>,
) => JSX.Element = ({
  formId,
  name,
  label,
  preview,
  onBlur,
  onChange,
  maxLength,
  testId,
  ...props
}) => {
  const fieldName = nameOf(name);
  const [field, { error, touched }] = useField(fieldName);
  const [infoMessage, setInfoMessage] = useState<ReactNode>(null);

  useEffect(() => {
    if (maxLength && field.value.length >= maxLength) {
      setInfoMessage(
        <Trans id="validation.textarea.maxLength">
          Napsali jste maximální množství znaků
        </Trans>,
      );
    } else {
      setInfoMessage(null);
    }
  }, [field.value]);

  return (
    <FieldWrapper>
      <TextAreaWrapper error={!!error} touched={touched} withLabel={!!label}>
        {label && <Label htmlFor={`${formId}_${name}`}>{label}</Label>}
        <TextArea
          id={`${formId}_${name}`}
          error={!!error}
          touched={touched}
          preview={preview}
          disabled={preview}
          maxLength={maxLength}
          type="textarea"
          {...field}
          {...props}
          onBlur={(e) => {
            field.onBlur(e);
            onBlur?.(e);
          }}
          onChange={(e) => {
            field.onChange(e);
            onChange?.(e);
          }}
          data-test={testId}
        />
      </TextAreaWrapper>
      <MessagesWrapper>
        <Error>{touched && error && error}</Error>
        <InfoMessage>{!!infoMessage && infoMessage}</InfoMessage>
      </MessagesWrapper>
    </FieldWrapper>
  );
};
