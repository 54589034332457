import { nameOf } from 'easy-tsnameof';
import { useField } from 'formik';
import React, { ReactNode } from 'react';

import { Error } from '../atomic/Error';
import { FieldWrapper } from '../atomic/FieldWrapper';
import { InputProps } from '../atomic/Input';
import { Label } from '../atomic/Label';
import { PhoneInput } from '../atomic/phoneInput';
import { PhoneInputSpecialProps } from '../atomic/phoneInput/types';
import { WithNameOfName } from '../types';
import { InputWrapper } from './styled';

type PhoneFieldProps<Fields> = {
  formId: string;
  label: ReactNode;
  testId?: string;
} & WithNameOfName<Fields, Omit<InputProps, 'type'>> &
  PhoneInputSpecialProps;

export const PhoneField: <Fields>(
  props: PhoneFieldProps<Fields>,
) => JSX.Element = ({
  formId,
  label,
  testId,
  name,
  autoComplete,
  ...props
}) => {
  const fieldName = nameOf(name);
  const [field, { error, touched }, { setValue, setTouched }] = useField(
    fieldName,
  );
  const handleChange = (phoneNumber: string) => {
    setValue(phoneNumber);
  };

  const handleBlur = (_: React.FocusEvent<HTMLInputElement>) => {
    setTouched(true);
  };

  const fieldProps = {
    ...field,
    onChange: handleChange,
    onBlur: handleBlur,
  };

  return (
    <FieldWrapper>
      <InputWrapper error={!!error} touched={touched} withLabel={!!label}>
        <Label htmlFor={`${formId}_${name}`}>{label}</Label>
        <PhoneInput
          id={`${formId}_${name}`}
          error={!!touched && !!error}
          touched={touched}
          {...fieldProps}
          {...props}
          testId={testId}
          autoComplete={autoComplete}
        />
      </InputWrapper>
      <Error>{touched && error}</Error>
    </FieldWrapper>
  );
};
