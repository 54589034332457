import { colors, typography } from '@hp/theme';
import React, { FC } from 'react';
import styled from 'styled-components';

const HintStyled = styled.div`
  padding-top: 4px;
  color: ${colors.gray_warm};
  font-size: ${typography.fontSize.bodySmall};
  line-height: ${typography.lineHeight.bodySmall};
`;

export const Hint: FC = ({ children, ...props }) => {
  return <HintStyled {...props}>{children || <>&nbsp;</>}</HintStyled>;
};
