import { PlaceIcon } from '@hp/atomic';
import { MessageDescriptor } from '@lingui/core';
import { nameOf } from 'easy-tsnameof';
import { useField } from 'formik';
import React, { ChangeEvent } from 'react';
import styled from 'styled-components';

import { Error } from '../atomic/Error';
import { FieldWrapper } from '../atomic/FieldWrapper';
import { Input, InputProps } from '../atomic/Input';
import { WithNameOfName } from '../types';
import { InputWrapper } from './styled';

// Redecalare forwardRef //todo: move somewhere globally
declare module 'react' {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

export type SearchFieldProps<Fields> = {
  formId: string;
  placeholder?: MessageDescriptor;
  onChange?: (e: ChangeEvent) => void;
  onBlur?: (e: FocusEvent) => void;
  customError?: React.ReactNode;
  customIcon?: React.ReactNode;
} & WithNameOfName<Fields, InputProps>;

const SearchIconStyled = styled(PlaceIcon)`
  position: absolute;
  top: 8px;
  right: 8px;
`;

const SearchFieldInner: <Fields>(
  props: SearchFieldProps<Fields>,
  ref: React.MutableRefObject<HTMLInputElement>,
) => JSX.Element = (
  { formId, name, onBlur, onChange, customError, height, ...props },
  ref,
) => {
  const fieldName = nameOf(name);
  const [field, { error, touched }] = useField(fieldName);

  return (
    <FieldWrapper>
      <InputWrapper height={height}>
        <Input
          id={`${formId}_${fieldName}`}
          error={(error && touched) || !!customError}
          touched={touched}
          noCheckMark
          {...field}
          {...props}
          onBlur={(e) => {
            field.onBlur(e);
            onBlur?.(e);
          }}
          onChange={(e) => {
            field.onChange(e);
            onChange?.(e);
          }}
          ref={ref}
        />
        <SearchIconStyled />
      </InputWrapper>

      <Error>{touched && error ? error : customError}</Error>
    </FieldWrapper>
  );
};

export const SearchField = React.forwardRef(SearchFieldInner);
