export const buildStreetAddress = (address: {
  route: string;
  premise: string;
  streetNumber: string;
}) => {
  let addressLine = '';

  if (address.route) {
    addressLine = `${address.route} `;
  }

  if (address.premise !== '') {
    addressLine += `${address.premise}`;
  }

  if (address.premise !== '' && address.streetNumber !== '') {
    addressLine += '/';
  }

  if (address.streetNumber !== '') {
    addressLine += address.streetNumber;
  }

  return addressLine;
};

export const buildCityAddress = (city: { locality: any; sublocality: any }) => {
  let cityLine = city.locality;

  if (cityLine === '') {
    cityLine += city.sublocality;
  }

  return cityLine;
};
