import { BorderPanelCss } from '@hp/atomic';
import { breakpoints, colors, spacing } from '@hp/theme';
import styled, { css } from 'styled-components';

import { FieldWrapper } from '../../atomic/FieldWrapper';
import { LabelWrapperProps } from './types';

export const SwitchButtonFieldWrapperStyled_Button = styled(FieldWrapper)`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  @media (min-width: ${breakpoints.minDesktop}) {
    width: 100%;
  }
`;

export const SwitchButtonFieldWrapperStyled_Chip = styled(FieldWrapper)`
  display: flex;
  align-items: flex-start;
`;

const cssMargins = css`
  :first-child {
    margin-left: 0;
  }

  :last-child {
    margin-right: 0;
  }
`;

type SBLW_Props = Omit<LabelWrapperProps, 'type'>;

export const SwitchButtonLabelWrapper_Button = styled.label<SBLW_Props>`
  display: flex;
  cursor: ${({ disabled }) => (disabled ? 'auto !important' : 'pointer')};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-self: stretch;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  margin: ${({ extraMarginBottom }) =>
    `0 ${spacing.xxs} ${extraMarginBottom ? '12px' : 0} ${spacing.xxs}`};

  cursor: ${({ disabled }) => (disabled ? 'auto !important' : 'pointer')};

  @media (min-width: ${breakpoints.midMobile}) {
    margin: ${({ extraMarginBottom }) =>
      `0 ${spacing.xs} ${extraMarginBottom ? '12px' : 0} ${spacing.xs}`};
  }

  ${BorderPanelCss};

  @media (min-width: ${breakpoints.minDesktop}) {
    ${({ extraMarginBetween }) =>
      extraMarginBetween
        ? css`
            margin-left: 11px;
            margin-right: 11px;
          `
        : ''};
  }

  ${cssMargins};
`;

export const SwitchButtonLabelWrapper_Chip = styled.label<SBLW_Props>`
  cursor: ${({ disabled }) => (disabled ? 'auto !important' : 'pointer')};
  display: flex;
  align-items: flex-start;
  justify-items: flex-start;
  padding: ${spacing.s} ${spacing.s};
  margin: ${spacing.unit(5)} ${spacing.xs};
  border-radius: 5px;
  background-color: ${({ checked }) =>
    checked ? colors.red_dark : colors.gray_light};
  color: ${({ checked }) => (checked ? colors.white : colors.black)};
  transition: background-color 0.5s;
`;
