import { colors, spacing } from '@hp/theme';
import React, { FC } from 'react';
import styled from 'styled-components';

export type RadioButtonProps = { isVisible?: boolean } & Omit<
  React.HTMLProps<HTMLInputElement>,
  'as' | 'ref' | 'label'
> /* due to ts/react conflict */;

const InputStyled = styled.input<RadioButtonProps>`
  display: none;
  height: 0;
  width: 0;

  &:checked ~ div.customRadioButton {
    background-color: ${colors.red_main};
    border: 2px solid ${colors.red_main};

    &:after {
      display: block;
    }
  }
`;

const CustomRadioButton = styled.div`
  flex-shrink: 0;
  position: relative;
  top: -2px;
  display: inline;
  height: 20px;
  width: 20px;
  background-color: ${colors.white};
  border: 1px solid ${colors.gray_mid};
  border-radius: 50%;
  margin-right: ${spacing.s};

  &:after {
    position: relative;
    display: none;
    content: '';
    width: 16px;
    height: 16px;
    border: 2px solid ${colors.white};
    border-radius: 50%;
    transform: rotate(45deg);
  }
`;

export const RadioButton: FC<RadioButtonProps> = ({ isVisible, ...props }) => (
  <>
    <InputStyled type="radio" {...props} />
    {isVisible && <CustomRadioButton className="customRadioButton" />}
  </>
);
